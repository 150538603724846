import * as React from "react"
import Layout from "../components/layout"
import Header from "../components/header";
import Footer from "../components/footer";
import image1 from "../images/designatives-1.png";
import image2 from "../images/designatives-2.png";
import image3 from "../images/designatives-3.png";
import image4 from "../images/designatives-4.png";
import piechart from "../images/pie-chart.png";
import map from "../images/map.png";
import {Helmet} from "react-helmet";

const TextContainerStyle = {
    paddingTop: '45%'
}
const TextStyle = {
    position: 'absolute',
    bottom: '0',
    marginBottom: '0'
}

const AboutUs = () => {
    return (
        <main>
            <Helmet>
                <title>Designatives - About us | A pixel happy creative agency</title>
                <meta name="description" content={"We're a very happy creative agency in Budapest, Hungary with a lot of passion for the work we do"}/>
            </Helmet>
            <Header/>
            <Layout>
                <div className={'about-us-page'}>
                    <section className="hero-section">
                        <div className="container h-100">
                            <div className="row h-100 align-items-center">
                                <div className={'col-12 col-md-6 order-2 order-md-1'}>
                                    <h1 className={'level3'}>Global projects, local talent</h1>
                                    <p>We’re extremely happy that we’re able to work with startups and large enterprises from various parts of the world. Every single project is carefully crafted by our team in Budapest, Hungary.</p>
                                </div>

                                <div className={'col-12 col-md-6 order-1 order-md-2'}>
                                    <img className={'d-xl-none map-image'} src={map} alt="Designatives"/>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="piechart-section mt-5 mt-md-0">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className={'col-12 col-md-6'}>
                                    <img src={piechart} alt={'Designatives'}/>
                                </div>
                                <div className={'col-12 col-md-6'}>
                                    <h2 className={'level3'}>Every slice is delicious</h2>
                                    <p>We work on a bunch of things, from logo design to complete branding and visual identity, from simple websites to complex web applications. We love what we do and we constantly look for ways to broaden our horizons.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="images-section">
                        <div className="container">
                            <div className="row">
                                <div className={'col-12'}>
                                    <div className={'dn-grid'}>
                                        <div className={'grid-p50 item'}>
                                            <div className={'image'}>
                                                <img src={image1} alt={'Designatives'}/>
                                            </div>
                                        </div>
                                        <div className={'grid-p50 item item-right'} style={TextContainerStyle}>
                                            <div className={'text'}>
                                                <h2 className={'level3'} style={TextStyle}>We move pixels into place.</h2>
                                            </div>
                                        </div>

                                        <div className={'grid-p80 item item-right'}>
                                            <div className={'image'}>
                                                <img src={image2} alt={'Designatives'}/>
                                            </div>
                                        </div>

                                        <div className={'grid-p50 item'}>
                                            <div className={'image'}>
                                                <img src={image3} alt={'Designatives'}/>
                                            </div>
                                        </div>

                                        <div className={'grid-p50 item item-right'}>
                                            <div className={'text'}>
                                                <h3 className={'level5'}>Colorful office,
                                                    creative ideas,
                                                    positive vibes</h3>
                                            </div>
                                        </div>

                                        <div className={'grid-p50 item item-right'}>
                                            <div className={'image'}>
                                                <img src={image4} alt={'Designatives'}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                </div>
            </Layout>
            <Footer/>
        </main>
    )
}

export default AboutUs
