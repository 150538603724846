import React from "react"

const WorkArrow = () => (
    <svg viewBox="0 0 99 21" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g className={'arrow'} transform="translate(-905.000000, -1052.000000)" stroke="#000000">
                <g transform="translate(906.500000, 1054.000000)">
                    <line x1="0.5" y1="8.5" x2="90.5" y2="8.5" strokeWidth="3" strokeLinecap="square"/>
                    <polyline strokeWidth="4" points="81.165778 0 93.665778 8.5 81.165778 17"/>
                </g>
            </g>
        </g>
    </svg>
)

export default WorkArrow
