import React from "react";
import {Link} from "gatsby";
import {email, instagram, facebook} from "../config";
import WorkArrow from "./workArrow";

class Footer extends React.Component {
    render() {
        return (
            <footer>
                <div className={'container'}>
                    <div className={'row'}>
                        <div className={'col-12'}>
                            <h2 className={'mb-0 mb-lg-5'}>
                                Feel free <br/>
                                to disturb!
                            </h2>
                        </div>
                        <div className={'col-12'}>
                            <a href="https://calendly.com/designatives/30-minute-meeting" target={'_blank'} rel={'noreferrer'}>
                                <div className={'btn btn-white'}>
                                    <WorkArrow/>
                                    Book a meeting
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className={'row mt-5'}>
                        <div className={'col-12 col-md-4 pe-5'}>
                            <fieldset>
                                <legend>Contact us</legend>
                                {email}
                            </fieldset>
                        </div>
                        <div className={'col-12 col-md-4 pe-5'}>
                            <fieldset>
                                <legend>Visit us</legend>
                                <a href="https://goo.gl/maps/p27cfAJAFvn" target={'_blank'} rel={'noreferrer'}>
                                    1011 Budapest, Hungary <br/>
                                    Bem rakpart 25/b
                                </a>
                            </fieldset>
                        </div>
                        <div className={'col-12 col-md-4 pe-5'}>
                            <fieldset>
                                <legend>Follow us</legend>
                                <a href={instagram} target={'_blank'} rel={'noreferrer'} className={'button mb-3'}>Instagram</a>
                                <a href={facebook} target={'_blank'} rel={'noreferrer'} className={'button'}>Facebook</a>
                            </fieldset>
                        </div>
                    </div>
                    <div className={'row copyright mt-5'}>
                        <div className={'col-auto pe-5 text-start'}>
                            <Link to={'/privacy-policy'}>
                                Privacy Policy
                            </Link>
                        </div>
                        <div className={'col-auto ps-md-5 text-start'}>
                            2022
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;
